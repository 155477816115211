import useGeneralStyles from "@components/Services/styles";
import React, { useCallback, useContext } from "react";
import useStyles from "./Notification48hs.styles";
import { ButtonPrimary } from "@components/Reusables";

import { EXITO_IMAGE } from "images/GoogleCloud";
import { useForm } from "@hooks";
import { useCurrentUser } from "@apollo";
import { LoadingContext } from "@context";
import { useApolloClient } from "@apollo/client";
import { navigate } from "gatsby";
import { Route } from "@interfaces";

/* interface ConsumptioOptionsProps {
 
} */

const Notification48: React.FC = () => {
  const classes = useStyles();
  const { user: currentUser } = useCurrentUser();
  const [form] = useForm({});
  
  const isPersonalDataFilled = () => { 
    return currentUser?.isPersonalDataCompleted
  }

  const handleCompleteData = () => { 
    navigate(Route.UserInformation)
  }

  return (
    <>
      {isPersonalDataFilled() ? (
        <section className={classes.container}>
          <div>
            <img className={classes.img} src={EXITO_IMAGE} />
          </div>
          <div>
            <span className={classes.title}>¡perfecto!</span>
            <span className={classes.subtitle}>
              Relizaremos la búsqueda de las mejores condiciones para ti. Te
              notificaremos en 48 hrs a este correo.
            </span>
            <span className={classes.email}>{currentUser?.email}</span>
            <ButtonPrimary
              className={classes.buttonPrimary}
              noDegraded
              onClick={() => {
                navigate(Route.services);
              }}
              text="Listo"
            />
          </div>
        </section>
      ) : (
        <section className={classes.container}>
          <div>
            <img className={classes.img} src={EXITO_IMAGE} />
          </div>
          <div>
            <span className={classes.title}>Rellena tus datos para una mejor evaluación</span>
            <span className={classes.subtitle}>
              Para entregarte alternativas más convenientes y agilizar la evaluación nos ayudaría que completes algunos datos
            </span>
            <ButtonPrimary
              className={classes.buttonPrimary}
              noDegraded
              onClick={() => {
                handleCompleteData()
              }}
              text="Completar datos"
            />
          </div>
        </section>
      )}
    </>
  );
};
export default Notification48;
