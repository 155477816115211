import React, { useCallback } from "react";
import useStyles from "./Notification24hs.styles";
import { ButtonPrimary } from "@components/Reusables";

import { EXITO_IMAGE } from "images/GoogleCloud";
import { useCurrentUser } from "@apollo";
import { useForm } from "@hooks";
import { navigate } from "@reach/router";
import { Route } from "@interfaces";

interface Notification24Props {
  setNeedCreditSelect?: (value: {
    status: boolean;
    value: number;
    subvalue: number;
  }) => void;
  secondText?: boolean;
}
 
const Notification24: React.FC<Notification24Props> = ({
  setNeedCreditSelect,
  secondText,
}) => {
  const classes = useStyles();
  const { user: currentUser } = useCurrentUser();
  const [form] = useForm({});

  const isPersonalDataFilled = () => { 
    return currentUser?.isPersonalDataCompleted
  }

  const handleCompleteData = () => { 
    navigate(Route.UserInformation)
  }

  return (
    <>
      {isPersonalDataFilled() ? (
        <section className={classes.container}>
          <div>
            <img className={classes.img} src={EXITO_IMAGE} />
          </div>
          <div>
            <span className={classes.title}>¡perfecto!</span>
            <span className={classes.subtitle}>
              {!secondText && (
                <>
                  En 24 hrs te entregaremos un análisis de tu solicitud a este
                  correo
                </>
              )}
              {secondText && (
                <>
                  Analizaermos esta información y te contactaremos si existen
                  mejores condiciones que puedas elegir. Te responderemos en 24hrs
                  a este correo
                </>
              )}
            </span>
            <span className={classes.email}>{currentUser?.email}</span>
            <ButtonPrimary
              className={classes.buttonPrimary}
              noDegraded
              onClick={() => {
                navigate(Route.services);
              }}
              text="Listo"
            />
          </div>
        </section>
      ): (
        <section className={classes.container}>
          <div>
            <img className={classes.img} src={EXITO_IMAGE} />
          </div>
          <div>
            <span className={classes.title}>Rellena tus datos para una mejor evaluación</span>
            <span className={classes.subtitle}>
              Para entregarte alternativas más convenientes y agilizar la evaluación nos ayudaría que completes algunos datos
            </span>
            <ButtonPrimary
              className={classes.buttonPrimary}
              noDegraded
              onClick={() => {
                handleCompleteData()
              }}
              text="Completar datos"
            />
          </div>
        </section>
      )}
    </>
  );
};
export default Notification24;
