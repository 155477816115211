import useGeneralStyles from "@components/Services/styles";
import ServiceButton from "@components/Services/ServiceButton/ServiceButton";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { navigate } from "gatsby";
import useStyles from "./advisory.style";
import { ButtonPrimary } from "@components";
import HeaderServices from "../Layout/Header/Header";
import { Route } from "@interfaces";
import { LayoutMobileInvestments } from "@components/Investments/Layout";
import { useMediaQuery } from "@material-ui/core";

interface advisoryProps {
  setStepSearching: (value: number) => void;
  setPathRoute: (value: JSX.Element) => void;
}

const Advisory: React.FC<recurringPaymentsProps> = ({
  setStepSearching,
  setPathRoute,
}) => {
  const classesGeneral = useGeneralStyles();
  const classes = useStyles();
  const isMobile = useMediaQuery("(max-width: 1200px)");
  useEffect(() => {
    setPathRoute(<></>);
  }, []);

  return (
    <>
      <HeaderServices
        title="AGENDA TU COACH FINANCIERO"
        onBackClick={() => {
          navigate(Route.services);
        }}
      />
      <section className={classesGeneral.container}>
        <div className={classes.containerItems}>
          <div className={classes.title}>
            Agenda una reunión para realizar una planificación detallada de tus
            finanzas. La primera sesión
            <strong> ¡es gratis!</strong>
          </div>
          <div className={classes.containerButton}>
            <ButtonPrimary
              noDegraded
              onClick={() => {
                // go to https://calendly.com/rokinapp/30min
                window.open(
                  "https://calendly.com/rokinapp/sesioninicial",
                  "_blank"
                );
              }}
              text="Ir al calendario"
            />
          </div>
          <div className={classes.title2}>
            Si tienes dudas, también puedes conversar con un asistente financiero
            <strong> a través del chat.</strong>
          </div>
        </div>
      </section>{" "}
      {isMobile && (
        <LayoutMobileInvestments activeServices>
          <></>
        </LayoutMobileInvestments>
      )}
    </>
  );
};
export default Advisory;
