import React, { useCallback, useContext, useEffect, useState } from "react";
import { navigate, PageProps } from "gatsby";
import { makeStyles, useMediaQuery } from "@material-ui/core";
import { SEO } from "@components";
import { LoadingContext } from "@context";
import { getAuth } from "firebase/auth";
import LayoutServices from "@components/Services/Layout/LayoutServices";
import { Route } from "@interfaces";
import Advisory from "@components/Services/Advisory/advisory";

import Notification48 from "@components/Services/Credits/Credits/Credits/Searching/Notification48hs/Notification48";
import Notification24 from "@components/Services/Credits/Credits/Credits/Searching/Notification24hs/Notification24";
import Notification from "@components/Services/Credits/Credits/Credits/Searching/Notification/Notification";
import { useAuth } from "@hooks";

const SaveByRokinPage: React.FC<PageProps> = () => {
  useAuth({ redirectIfNotLogged: true });

  const classes = useStyles();
  const { hideLoader } = useContext(LoadingContext);
  const currentUser = getAuth().currentUser;

  const [recurringPaymentCategory, setRecurringPaymentCategory] = useState(-1);
  const [pathRoute, setPathRoute] = useState<JSX.Element>();
  const [stepSearching, setStepSearching] = useState(-1);
  const [stepSubCategory, setStepSubCategory] = useState(-1);

  const [finishSave, setFinishSave] = useState({
    Notification48hs: false,
    Notification24hs: false,
    Notification: false || undefined,
  });
  const [numberStep, setNumberStep] = useState(-1);

  const onBackClick = useCallback(() => {
    recurringPaymentCategory === -1 && navigate(Route.services);
    numberStep !== -1 && setNumberStep(-1);
  }, [recurringPaymentCategory, stepSearching, numberStep]);

  let StepSearchingSelect;

  switch (stepSearching) {
    case -1:
      StepSearchingSelect = (
        <Advisory
          setStepSearching={setStepSearching}
          setPathRoute={setPathRoute}
        />
      );
      break;
    default:
      break;
  }
  const isMobile = useMediaQuery("(max-width: 1200px)");

  return (
    <>
      <SEO />
      {finishSave.Notification48hs && <Notification48 />}
      {finishSave.Notification24hs && <Notification24 />}
      {finishSave.Notification && <Notification />}

      {!finishSave.Notification48hs &&
        !finishSave.Notification &&
        !finishSave.Notification24hs && (
          <LayoutServices
            state={pathRoute}
            title={"AGENDA TU ASESORÍA"}
            onBackClick={onBackClick}
            key="Bottom"
            hiddenHeader={!isMobile}
            activeServices
          >
            {StepSearchingSelect}
          </LayoutServices>
        )}
    </>
  );
};

export default SaveByRokinPage;

const useStyles = makeStyles((theme) => ({}));
