import { makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles<Theme>((theme) => ({
	containerItems: {
		display: "flex",
		gap: "3rem",
		flexDirection: "column",
	},
	title: {
		fontSize: "1rem",
		fontWeight: 400,
		textAlign: "center",
		color: theme.palette.text.secondary,
		fontFamily: "Montserrat",
	},
	title2: {
		fontSize: "1rem",
		fontWeight: 400,
		textAlign: "center",
		color: theme.palette.text.secondary,
		fontFamily: "Montserrat",
	},
	containerButton: {
		display: "flex",
		justifyContent: "center",
	},
}));
export default useStyles;
