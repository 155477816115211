import useGeneralStyles from "@components/Services/styles";
import React from "react";
import useStyles from "./Notification.styles";
import { ButtonPrimary } from "@components/Reusables";
import { EXITO_IMAGE } from "images/GoogleCloud";
import { useCurrentUser } from "@apollo";
import { navigate } from "gatsby";
import { Route } from "@interfaces";

interface Notification24Props {
  setNeedCreditSelect?: (value: {
    status: boolean;
    value: number;
    subvalue: number;
  }) => void;
}

const Notification: React.FC<Notification24Props> = ({
  setNeedCreditSelect,
}) => {
  const classesGeneral = useGeneralStyles();
  const classes = useStyles();
  const { user: currentUser } = useCurrentUser();
  
  const isPersonalDataFilled = () => { 
    return currentUser?.isPersonalDataCompleted
  }

  const handleCompleteData = () => { 
    navigate(Route.UserInformation)
  }

  return (
    <>
      {isPersonalDataFilled() ? (
        <section className={classes.container}>
          <div>
            <img className={classes.img} src={EXITO_IMAGE} />
          </div>
          <div>
            <span className={classes.title}>¡NOS PARECE PERFECTO!</span>
            <span className={classes.subtitle}>
              Si necesitas nuestra ayuda, no dudes en escribirnos.
            </span>
            <span className={classes.email}>{currentUser?.email}</span>
            <ButtonPrimary
              className={classes.buttonPrimary}
              noDegraded
              onClick={() => {
                navigate(Route.services);
              }}
              text="Listo"
            />
          </div>
        </section>
      ) : (
        <section className={classes.container}>
          <div>
            <img className={classes.img} src={EXITO_IMAGE} />
          </div>
          <div>
            <span className={classes.title}>Rellena tus datos para una mejor evaluación</span>
            <span className={classes.subtitle}>
              Para entregarte alternativas más convenientes y agilizar la evaluación nos ayudaría que completes algunos datos
            </span>
            <ButtonPrimary
              className={classes.buttonPrimary}
              noDegraded
              onClick={() => {
                handleCompleteData()
              }}
              text="Completar datos"
            />
          </div>
        </section>
      )}
    </>
  );
};
export default Notification;
